<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ article.title }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.articles.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Title" placeholder="Please enter a title" outlined :rules="nameRules" required v-model="article.title" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="article_text">
                            <label>Text</label>
                            <ckeditor :editor="editor" v-model="article.text" required></ckeditor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                :items="languages"
                                v-model="article.language"
                                required
                                outlined
                                label="Select a language"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created at" v-model="article.created_at" disabled ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveArticle">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Article from "../../../models/Article";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "articles-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            article: {},
            formValid: true,
            nameRules: [
                v => !!v || 'Title is required',
                c => !!c || 'Text is required',
            ],
            languages : [
                'hy',
                'ru',
                'en'
            ],
            editor: ClassicEditor,
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods: {
        async handleSaveArticle() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const article = new Article(this.article);
                this.article = await article.save()
                await this.$router.push({name: 'admin.articles.show', params: {id: this.article.id}}).catch(()=>{});
                this.snackbar = true
            }
        }
    },
    async mounted() {
        const articleId = this.$route.params.id
        if (articleId) {
            this.article = await Article.find(articleId)
        }
    },
}
</script>

<style lang="scss">
    .article_text {
        .ck-editor {
            &__main{
                .ck-content {
                    height:150px!important;
                }
            }
        }
    }
</style>
